import { Title } from '../Typography';
import { InstagramContainer } from './styled';
import { Row, Col } from 'antd';
import Image from 'next/image';
import { Button } from '../Form/Button/styled';

const InstagramSection = ({ posts }) => {
  if (!posts || posts.length === 0) {
    return null;
  }
  return (
    <InstagramContainer>
      <a href="https://www.instagram.com/classyclosetsus"><Title className="instagram-title">@ClassyClosetsUS</Title></a>
      <div>
        {/** The following will be an antd grid where on desktop it will be squares across and on mobile it will be 3 across */}
        <Row gutter={[8, 8]}>
          {posts.map((post) => {
            return (
              <Col xs={8} md={8} lg={4} key={post.href}>
                <a href={post.href} target="_blank" rel="noreferrer">
                  <Image
                    src={post.img}
                    alt={post.caption}
                    width={200}
                    height={200}
                    objectFit="cover"
                    layout="responsive"
                  />
                </a>
              </Col>
            )
          })}
        </Row>
      </div>
      <div style={{ textAlign: 'center', marginTop: '40px' }}>
        <a href="https://www.instagram.com/classyclosetsus" target="_blank" rel="noreferrer">
          <Button variant="outline">FOLLOW US</Button>
        </a>
      </div>
    </InstagramContainer>
  )
}

export default InstagramSection;