import styled from "styled-components";
export const InstagramContainer = styled.div`
margin-bottom: 2rem;
  text-align: center;
  padding: 0 1rem;
  .instagram-title {
    text-align: center;
    &::before {
      display: none;
    }
  }
  img {
    width: 100%;
  }
  a {
    display: block;
    margin-top: 10px;
    color: #000;
    font-size: 1.2rem;
    font-weight: bold;
    text-decoration: none;
  }
`;